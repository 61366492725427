// Styling for <footer> element.
& > footer {
  grid-area: footer;
  background-color: $colorLightBlue;
  text-align: center;
  border-top: 5px $colorGreen solid;

  .LI-badge-container {
    background-color: white;
  }

  copyright {
    padding: 10px;
  }
}
