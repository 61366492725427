/*
 * Grid styles
 */
$menusize: 3;

$colorBlack: #020304;
$colorPink: #E7007F;
$colorGreen: #61DD00;
$colorLightBlue: #2C7AD2;
$colorDarkBlue: #051C30;

$menuTransitionTime: .5s;
$menuTransitionDelay: 0s;

/* reset all margins and paddings */
* {
  margin: 0;
  padding: 0;
}

body {
  background-color: #eee;
}

img[alt=smallimage] {
  width: 300px;
}

#prezi { // embedded presi presentation
  width: 380px;
  height: 300px;
}

grid {
  display: grid;
  grid-template-columns: auto;
  grid-template-areas:
    ". header ."
    ". content ."
    ". footer .";
  // grid-row-gap: 1em;

  @import 'header';
  @import 'content';
  @import 'footer';

  sidebar {
    display: none;
    background-color: $colorGreen;
  }
}

@import 'highlighting';
@import 'paginate';
@import 'tagcloud';

p {
  margin-top: 1em;
}

/* Start hamburgers */
$hamburger-layer-color: white;
@import "hamburgers/hamburgers";
/* End hamburgers */

/*
 * Device specific styling
 */
@import 'firefox-menu';

/* iphones 3, 4, 5 portrait */
@media (min-width: 320px) {
  .highlight {
    max-width: 290px;
  }
}

/* iphone 6 portrait */
@media (min-width: 375px) {
  .highlight {
    max-width: 355px;
  }
}

/* iphone 3,4,5 landscape */
@media (min-width: 480px) {
  .highlight {
    max-width: 460px;
  }
}

/* iphone 6 landscape */
@media (min-width: 667px) {
  .highlight {
    max-width: 663px;
  }
}

/* different header on larger screens */
@media (min-width: 640px) {
  header img {
    /*
     * Does not work with Firefox.
     * '::after' alternative does not scale correctly
     */
    content: url("/assets/images/header-1920.png")
  }

  .hamburger {
    display: none;
  }

  #prezi { // embedded presi presentation
    width: 690px;
    height: 400px;
  }

  grid {
    header {
      nav {
        nav-menu {
          opacity: 1;
          // transition: none;
          height: auto;
          background-color: inherit;
          position: absolute;
          bottom: 5%;
          grid-auto-flow: column;
          grid-template-columns: auto;

          nav-menu-item {
            color: white;
            font-size: 20px;
            padding-left: 10px;
            padding-right: 10px;
            font-weight: 400;
          }

          .menu-item-selected {
            color: $colorGreen;
          }

          nav-menu-item:hover {
            background-color: inherit;
            color: $colorPink;
          }
        }
      }
    }
  }
}

/* half desktop */
@media only screen and (min-width: 960px) {
  grid {
    grid-template-columns: auto 710px 250px auto;
    grid-template-areas:
      ". header header ."
      ". content sidebar ."
      ". footer footer .";
    align-self: center;

    sidebar {
      display: inline;
      grid-area: sidebar;
    }
  }

  .highlight {
    // max-width: 98%;
  }

  footer .LI-profile-badge {
    display: none;
  }
}
