// Styling for 'content' element
content {
  @import 'archieve';

  grid-area: content;
  background-color: white;

  h1 {
    padding-left: 20px;
  }

  menu {
    display: grid;
    grid-template-rows: 1fr;
    grid-auto-flow: column;

    background-color: $colorLightBlue;
    padding-top: 5px;
    padding-bottom: 5px;

    menuitem {
      justify-self: center;
      font-weight: 400;
      font-size: 20px;
      color: $colorPink;

      a {
        text-decoration: none;
        color: white;
      }

      a:hover {
        text-decoration: underline;
      }
    }
  }

  posts {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: auto;
    // grid-row-gap: 1em;
    grid-auto-flow: row;

    heading {
      /*grid-area: heading;*/
      padding: 10px;
    }

    /* show list of posts */
    post {
      margin: 10px;
      padding: 10px;
      // border: 1px solid #595457;
      // border-radius: 5px;
      // box-shadow: 5px 5px 2px #595457;

      /*grid-area: posts;*/
      display: grid;
      grid-template-columns: 1fr;
      grid-template-areas:
        "title"
        "date"
        "tags"
        "excerpt";

      // &:nth-child(even) {
      //   background-color: #efefef;
      // }
      // &:nth-child(odd) {
      //   background-color: #e0dfe0;
      // }

      post-excerpt {
        padding-top: 5px;

        a {
          color: $colorPink;
        }
        a:visited {
          color: $colorPink;
          font-weight: 500;
        }
      }
    }
  }

  /* show single post */
  post {
    padding: 10px;
    /*grid-area: posts;*/
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      "date"
      "tags"
      "title"
      "content"
      "comments";

    post-date {
      grid-area: date;
      color: $colorPink;
      font-size: 12px;
    }

    post-title {
      grid-area: title;
      font-weight: 600;
      font-size: 20px;

      a {
        color: $colorDarkBlue;
        text-decoration: none;
      }
      a:visited {
        color: $colorDarkBlue;
      }
    }

    post-tags {
      grid-area: tags;
      color: $colorLightBlue;
      font-size: 12px;
    }

    post-content {
      grid-area: content;
      /* p directly after an h4, should not have margin-top */
      h4 {
        margin-top: 1em;
      }
      h4 + p {
        margin-top: 0;
      }

      ul {
        padding-left: 16px;
      }
    }

    post-comments {
      grid-area: comments;
    }
  }
}
