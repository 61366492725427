/*
 * Arrow Turn Up quick (reverse)
 */
.hamburger--arrowturn-up-90-left.is-active, input:checked ~ .hamburger--arrowturn-up-90-left {
  .hamburger-inner {
    transform: rotate(-90deg);

    &::before {
      transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1);
    }

    &::after {
      transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1);
    }
  }
}
