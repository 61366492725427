& > header {
  grid-area: header;
  position: relative;
  background-color: $colorPink;

  nav {

    input:checked ~ nav-menu {
      height: $menusize * 38px;
      opacity: 1;
      transition:
        height $menuTransitionTime ease-out $menuTransitionDelay,
        opacity $menuTransitionTime ease-out $menuTransitionDelay; /* property, duration, function, delay */
    }

    input:checked + label {
      span {
        opacity: 0;
        top: 50%;
      }

      span:first-child {
        opacity: 1;
        transform: rotate(405deg);
      }

      span:last-child {
        opacity: 1;
        transform: rotate(-405deg);
      }
    }

    input + label {
      position: absolute;
      top: 5%;
      left: 4%;
      height: 20px;
      width: 15px;
      z-index: 5;

      span {
        position: absolute;
        display: block;
        width: 100%;
        height: 2px;
        left: 0;
        top: 50%;
        margin-top: -1px;
        background: $colorBlack;
        transition: .5s;
      }

      span:first-child {
        top: 3px;
      }

      span:last-child {
        top: 16px;
      }
    }

    label:hover {
      cursor: pointer;
    }

    nav-menu {
      display: grid;
      grid-template-columns: 1fr;
      height: 0;
      opacity: 0;
      transition:
        height $menuTransitionTime ease-out $menuTransitionDelay,
        opacity $menuTransitionTime ease-out $menuTransitionDelay; /* property, duration, function, delay */

      // coloring menu background
      //background-image: url(/assets/images/mobile-logo-600-background.png);
      //background-repeat: repeat-y;
      //background-size: 100%;

      background-color: $colorGreen;

      nav-menu-item {
        // justify-self: center;
        text-align: center;
        font-weight: 600;
        font-size: 24px;
        color: $colorPink;
        padding: 5px;

        a {
          text-decoration: none;
          color: inherit;
        }

        a:hover {
          // text-decoration: underline;
        }
      }

      nav-menu-item:hover {
        background-color: $colorPink;
        color: $colorGreen;
      }
    }
  }
}
