/* Styling archieve page */
archieve {
  display: block;
  padding: 10px;

  name {
    font-family: "Verdana, Geneva, sans-serif";
    color: $colorPink;
    font-weight: 600;
    font-size: 22px;
  }
  yearlist {
    display: block;
    padding: 10px;
    year {
      font-size: 20px;
      font-weight: 600;
      color: $colorDarkBlue;
    }
    list {
      display: grid;
      grid-template-columns: 1fr;
      padding: 10px;

      item {
        display: grid;
        grid-template-columns: 30% 70%;
        grid-template-areas:
          "date title";
        padding-top: 3px;
        padding-bottom: 3px;
        border-bottom: 1px solid rgba(0,0,0,0.3);
        color: $colorLightBlue;

        date {
          grid-area: date;
          opacity: 0.7;
        }
        span {
          grid-area: title;

          a {
            text-decoration: none;
          }
        }
      }
    }
  }
}
